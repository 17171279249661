import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProductCardList.module.scss';
import classNames from 'classnames';
import { ucFirst } from '../../utils/caseconverters';
import ProductCurrentCard from '../ProductCurrentCard';
import ProductLegacyCard from '../ProductLegacyCard';
import ProductAccessoryCard from '../ProductAccessoryCard';
import ProductHighlightItem from '../ProductHighlightItem';

const ProductCardList = ({ type, products }) => {
    // Sort products based on category if type is 'accessory'

    const categorySortOrder = [
        "Soft cases",
        "Stands",
        "Pedals",
        "Miscellaneous",
    ]

    const sortedProducts = type === 'accessory'
        ? [...products].sort((a, b) => {
            const categoryA = a.category || 'No Category';
            const categoryB = b.category || 'No Category';
            const indexA = categorySortOrder.indexOf(categoryA);
            const indexB = categorySortOrder.indexOf(categoryB);

            if (indexA === -1) {
                return 1;
            }
            if (indexB === -1) {
                return -1;
            }

            return indexA - indexB;
        })
        : [...products];

    const classes = classNames(
        styles['ProductCardList__Grid'],
        styles['ProductCardList__Grid--' + ucFirst(type)]
    );

    const renderProductItem = (product, index) => {
        let itemComponent = null;
        if (type === 'current') {
            itemComponent = <ProductCurrentCard {...product} />;
            if (product.isHighLight) {
                itemComponent = <ProductHighlightItem {...product} />;
            }
        } else if (type === 'legacy') {
            itemComponent = <ProductLegacyCard {...product} />;
        } else if (type === 'accessory') {
            itemComponent = <ProductAccessoryCard {...product} />;
        }
        return (
            <div key={index} className={styles['ProductCardList__Item']}>
                {itemComponent}
            </div>
        );
    };

    // Render products without separate grid for other types
    if (type !== 'accessory') {
        return (
            <div className={styles['ProductCardList']}>
                <div className={classes}>
                    {products.map((product, index) =>
                        renderProductItem(product, index)
                    )}
                </div>
            </div>
        );
    }

    const groupedProducts = sortedProducts.reduce((acc, product) => {
        const category = product.category || 'No Category';
        if (!acc[category]) {
            acc[category] = [];
        }
        acc[category].push(product);
        return acc;
    }, {});

    return (
        <div className={styles['ProductCardList']}>
            {Object.entries(groupedProducts).map(([category, products]) => (
                <div
                    key={category}
                    className={styles['ProductCardList__CategoryContainer']}>
                    <div className={styles['ProductCardList__CategoryTitle']}>
                        {category}
                    </div>
                    <div className={styles['ProductCardList__CategoryGrid']}>
                        {products.map((product, index) =>
                            renderProductItem(product, index)
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

ProductCardList.propTypes = {
    type: PropTypes.oneOf(['current', 'legacy', 'accessory']).isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    isHighlight: PropTypes.bool.isRequired,
};

ProductCardList.defaultProps = {
    type: 'current',
    products: [],
    isHighlight: false,
};

export default ProductCardList;
