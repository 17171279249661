import PropTypes from 'prop-types';
import styles from './ProductAccessoryCard.module.scss';
import React from 'react';
import Icon from '../Icon';

import Image from '../Image';

const ProductAccessoryCard = ({ productImage, title, description, link }) => {
    return (
        <a href={link.href} className={styles['ProductAccessoryCard']}>
            <div className={styles['ProductAccessoryCard__Container']}>
                <div className={styles['ProductAccessoryCard__ImageWrap']}>
                    {productImage && productImage.url && (
                        <>
                            <h2 className="sr-only">{title}</h2>
                            <Image
                                {...productImage}
                                layout="fill"
                                objectFit="contain"
                                priority={true}
                            />
                        </>
                    )}
                </div>
                <div className={styles['ProductAccessoryCard__Content']}>
                    <div>
                        <h3 className={styles['ProductAccessoryCard__Title']}>
                            {title}
                        </h3>
                        <p
                            className={
                                styles['ProductAccessoryCard__Description']
                            }>
                            {description}
                        </p>
                    </div>
                    <div className={styles['ProductAccessoryCard__Button']}>
                        <p
                            className={
                                styles['ProductAccessoryCard__ButtonText']
                            }>
                            Read more
                        </p>
                        <Icon type="arrow" size="large" />
                    </div>
                </div>
            </div>
        </a>
    );
};

ProductAccessoryCard.propTypes = {
    productImage: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        focal: PropTypes.shape({
            x: PropTypes.number.isRequired,
            y: PropTypes.number.isRequired,
        }).isRequired,
    }),
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.shape({
        href: PropTypes.string.isRequired,
    }),
};

ProductAccessoryCard.defaultProps = {
    link: {
        href: '#',
    },
};

export default ProductAccessoryCard;
