import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './ProductCurrentCard.module.scss';
import Icon from '../Icon';

import Image from '../Image';

const ProductCurrentCard = ({
    title,
    link,
    productLogoImage,
    productImage,
    description,
}) => {
    return (
        <a href={link.href} className={styles['ProductCurrentCard']}>
            <div className={styles['ProductCurrentCard__Container']}>
                <div
                    className={
                        styles['ProductCurrentCard__DescriptionWrapper']
                    }>
                    {productLogoImage && productLogoImage.url && (
                        <div
                            className={
                                styles['ProductCurrentCard__LogoImageWrapper']
                            }>
                            <h2 className="sr-only">{title}</h2>
                            <Image
                                {...productLogoImage}
                                layout="fill"
                                objectFit="contain"
                                priority={true}
                            />
                        </div>
                    )}
                    <div
                        className={
                            styles['ProductCurrentCard__DescriptionTextWrapper']
                        }>
                        <p
                            className={
                                styles['ProductCurrentCard__Description']
                            }>
                            {description}
                        </p>
                    </div>
                    <div className={styles['ProductCurrentCard__Button']}>
                        <p className={styles['ProductCurrentCard__ButtonText']}>
                            Read more
                        </p>
                        <Icon type="arrow" size="large" />
                    </div>
                </div>

                {productImage && (
                    <div className={styles['ProductCurrentCard__Image']}>
                        <div
                            className={
                                styles[
                                'ProductCurrentCard__ProductImageWrapper'
                                ]
                            }>
                            <Image
                                {...productImage}
                                className={classNames(
                                    styles['ProductCurrentCard__ProductImage']
                                )}
                                layout="fill"
                                objectFit="contain"
                                priority={true}
                            />
                        </div>
                    </div>
                )}
            </div>
        </a>
    );
};

ProductCurrentCard.propTypes = {
    link: PropTypes.shape({
        href: PropTypes.string,
    }),
    productLogoImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    productImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    description: PropTypes.string,
};

ProductCurrentCard.defaultProps = {
    link: {
        href: '#',
    },
    productLogoImage: null,
    productImage: null,
};

export default ProductCurrentCard;
