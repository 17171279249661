import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './ProductLegacyCard.module.scss';
import Icon from '../Icon';
import Image from '../Image';

const ProductLegacyCard = ({
    title,
    link,
    productLogoImage,
    productImage,
    description,
}) => {
    return (
        <a href={link.href} className={styles['ProductLegacyCard']}>
            <div className={styles['ProductLegacyCard__Container']}>
                {productLogoImage && (
                    <div
                        className={
                            styles['ProductLegacyCard__LogoImageWrapper']
                        }>
                        <h2 className="sr-only">{title}</h2>
                        <Image
                            url={productLogoImage.url}
                            layout="fill"
                            objectFit="contain"
                            priority={true}
                            alt={productLogoImage.alt}
                        />
                    </div>
                )}

                {productImage && (
                    <div
                        className={
                            styles['ProductLegacyCard__ProductImageWrapper']
                        }>
                        <Image
                            {...productImage}
                            layout="fill"
                            objectFit="cover"
                            priority={true}
                        />
                    </div>
                )}
                <p className={styles['ProductLegacyCard__Description']}>
                    {description}
                </p>
                <div className={styles['ProductLegacyCard__Button']}>
                    <p className={styles['ProductLegacyCard__ButtonText']}>
                        Read more
                    </p>
                    <Icon type="arrow" size="large" />
                </div>
            </div>
        </a>
    );
};

ProductLegacyCard.propTypes = {
    link: PropTypes.shape({
        href: PropTypes.string,
    }),
    productLogoImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    productImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    description: PropTypes.string,
};

ProductLegacyCard.defaultProps = {
    link: {
        href: '#',
    },
    productLogoImage: null,
    productImage: null,
    description: '',
};

export default ProductLegacyCard;
