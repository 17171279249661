import PropTypes from 'prop-types';
import React from 'react';
import styles from './ProductHighlightItem.module.scss';
import Image from '../Image';
import Icon from '../Icon';

const ProductHighlightItem = ({
    title,
    link,
    productLogoImage,
    productImage,
    description,
}) => {
    return (
        <a href={link.href} className={styles['ProductHighlightItem']}>
            <div className={styles['ProductHighlightItem__Container']}>
                {productLogoImage && (
                    <div
                        className={
                            styles['ProductHighlightItem__DescriptionWrapper']
                        }>
                        <div
                            className={
                                styles['ProductHighlightItem__LogoImageWrapper']
                            }>
                            <h2 className="sr-only">{title}</h2>
                            <Image
                                {...productLogoImage}
                                layout="fill"
                                objectFit="contain"
                                priority={true}
                            />
                        </div>
                        <div
                            className={
                                styles[
                                'ProductHighlightItem__DescriptionTextWrapper'
                                ]
                            }>
                            <p
                                className={
                                    styles['ProductHighlightItem__Description']
                                }>
                                {description}
                            </p>

                            <div className={styles['ProductHighlightItem__Button']}>
                                <p className={styles['ProductHighlightItem__ButtonText']}>
                                    Read more
                                </p>
                                <Icon type="arrow" size="large" />
                            </div>
                        </div>
                    </div>
                )}
                {productImage && (
                    <div className={styles['ProductHighlightItem__Image']}>
                        <div
                            className={
                                styles[
                                'ProductHighlightItem__ProductImageWrapper'
                                ]
                            }>
                            <Image
                                {...productImage}
                                layout="fill"
                                objectFit="contain"
                                priority={true}
                            />
                        </div>
                    </div>
                )}
            </div>
        </a>
    );
};

ProductHighlightItem.propTypes = {
    link: PropTypes.shape({
        href: PropTypes.string,
    }),
    productLogoImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    productImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    description: PropTypes.string,
};

ProductHighlightItem.defaultProps = {
    link: {
        href: '#',
    },
    productLogoImage: null,
    productImage: null,
    description: '',
};

export default ProductHighlightItem;
